import React, { useState } from "react";
import LinkIcon from "../images/link-icon.png";

export default function Experience({ experience }) {
  const [expandedPoints, setExpandedPoints] = useState([]);

  const toggleDescription = (index) => {
    setExpandedPoints((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const styles = {
    section: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      fontSize: "1.8vw",
      fontFamily: "RalewayExtraBold",
      marginBottom: "1vw",
      color: "#FFF",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
    },
    block: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "60%",
      borderRadius: "1vw",
      padding: "2vw",
      margin: "auto",
      marginBottom: "2vw",
      // backgroundColor: "rgba(256, 256, 256, 0.2)",
      backgroundColor: "#a25ec4",
      color: "#FFF",
    },
    duration: {
      fontSize: "1.2vw",
      textAlign: "left",
      marginBottom: "0.5vw",
      color: "#f7ea88",
      fontFamily: "RaleWayBold",
    },
    introduction: {
      fontSize: "1.1vw",
      textAlign: "left",
      margin: "1vw",
      color: "#FFF",
      fontFamily: "RalewayRegular"
    },
    bulletpointContainer: {
      display: "flex",
      alignItems: "center",
    },
    bulletpoint: {
      fontSize: "1.1vw",
      marginBottom: "2vw",
      fontFamily: "RalewayMedium",
      color: "#f7ea88",
    },
    toggleText: {
      fontSize: "1.1vw",
      color: "#f7ea88",
      cursor: "pointer",
      marginLeft: "1vw",
    },
    description: {
      fontSize: "1vw",
      marginBottom: "2vw",
      fontFamily: "RalewayRegular",
      marginLeft: "2vw",
      display: "none",
    },
    linkIcon: {
      width: "3vw",
      height: "3vw",
      cursor: 'url("../../images/cursor-hover.png"), auto !important',
      position: "absolute",
      top: 0,
      right: 0,
    },
    link: {
      position: "relative",
    },
    image: {
      maxWidth: "60%",
      width: "60vw",
      height: "40vw%",
      margin: "1vw",
    },
  };

  return (
    <div style={styles.section}>
      <div style={styles.block}>
        <div style={styles.titleContainer}>
          <div style={styles.title}>{experience.title}</div>
          {experience.link && (
            <div style={styles.link}>
              <a
                href={experience.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LinkIcon}
                  alt={`${experience.title} Link`}
                  style={styles.linkIcon}
                />
              </a>
            </div>
          )}
        </div>
        {experience.image && (
          <div style={{ textAlign: "center" }}>
            <img
              src={experience.image}
              alt={experience.title}
              style={styles.image}
            />
          </div>
        )}
        <div style={{ fontSize: "1.2vw", color: "#f7ea88", fontFamily: "RaleWayBold" }}>
          Technologies: {experience.technologies}
        </div>
        <div style={styles.duration}>Duration: {experience.duration}</div>
        {experience.introduction && (
          <div style={styles.introduction}>
            {experience.introduction}
          </div>
        )}
        {experience.points.map((point, index) => (
          <div key={index}>
            <div style={styles.bulletpointContainer}>
              <div
                style={styles.bulletpoint}
                onClick={() => toggleDescription(index)}
              >
                ☆ {point.title}
              </div>
              <div
                style={styles.toggleText}
                onClick={() => toggleDescription(index)}
              >
                {expandedPoints[index] ? "▲" : "▼"}
              </div>
            </div>
            <div
              style={{
                ...styles.description,
                display: expandedPoints[index] ? "block" : "none",
              }}
            >
              {point.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );  
}
