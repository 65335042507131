import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Element } from "react-scroll";
import Constellation from "./components/Constellation";
import NavigationBar from "./components/NavigationBar";
import MainPage from "./components/MainPage";
import About from "./components/About";
import Experience from "./components/Experience";
import ProjectCarousel from "./components/ProjectCarousel";
import Contact from "./components/Contact";
import YudoCoreExercises from "./images/yudo-coreexercises.png";
import YudoHomePage from "./images/yudo-homepage.png";
import YudoLiveFeed from "./images/yudo-livefeed.png";
import YudoMath from "./images/yudo-math.png";
import Enspo from "./images/enspo.jpg";
import BaseMapGray from "./images/basemap-gray.png";
import BaseMapRed from "./images/basemap-red.png";
import BaseMapPath from "./images/basemap-path.png";
import RobotArm from "./images/robot-arm.jpg";
import RobotCircuit from "./images/robot-circuit.jpg";
import RobotTech from "./images/robot-tech.jpg";
import RobotDetection from "./images/robot-detection.jpg";
import CapybaraMain from "./images/capybara-main.png";
import CapybaraFed from "./images/capybara-fed.png";
import CapybaraHistory from "./images/capybara-history.png";
import Cybercision from "./images/cybercision.jpeg";
import ReCallMain from "./images/recall-main.jpg";
import ReCallMap from "./images/recall-map.jpg";
import ReCallPanel from "./images/recall-panel.jpg";


const projects = [
  {
    title: "ReCall - Memories Done for You",
    link: "https://devpost.com/software/recall-memories-done-for-you",
    description: `
      Winner of the LLM enhancer category at Hack the North 2024, a $2000 prize.
      A camera records you as you go about your day to day life, storing "comic book strip" panels containing images
      and context of what you're doing as you go about your life. When you want to remember something you can ask out 
      loud, and it'll use Open AI's API to search through its "memories" to bring up the location, time, and your 
      action when you lost it. This can help with knowing where you placed your keys, if you locked your door/garage, 
      and other day to day life. The React-based UI interface records using your webcam, screenshotting every second, 
      and stopping at the 9 second mark before creating a 3x3 comic image.
    `,
    technologies: ["JavaScript, ExpressJS, Firebase, OpenAI Turbo Vision Model"],
    images: [ReCallMain, ReCallMap],
  },
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "Yudo",
    link: "https://github.com/skyleapa/Yudo",
    description: `
      First Place against 665 participants at NwHacks 2023, Western Canada's Largest Hackathon. 
      Yudo, an exercise web app, leverages TensorFlow AI and a custom-developed exercise detection algorithm to assist users 
      in improving their exercise form. Users select a workout, and Yudo provides a demo video, scoring them based on form accuracy.
      The app utilizes BlazePose and JSON to stream video data into TensorFlow, creating dynamic 2D visualizations of exercise forms. 
      OpenAI integration with GPT-3 provides personalized feedback on plank form through an ExpressJS back-end, helping users 
      identify and correct form discrepancies. The front end was designed with Figma and Procreate and implemented with React.
      `,
    technologies: [
      "React",
      "Node.js",
      "Tensorflow",
      "Blazepose",
      "ExpressJS",
      "Axios",
      "Figma",
      "GPT-3",
    ],
    images: [YudoHomePage, YudoLiveFeed, YudoCoreExercises, YudoMath],
  },
// ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "BaseMap Optimizer",
    link: "https://github.com/skyleapa/basefinder",
    description: `
      Developed an algorithm utilizing Breadth-First Search (BFS) traversal and distance encoding to generate a structured path 
      simulating the ideal base location. This is based on the idea that opposing teams have bases strategically placed at the furthest 
      distance apart on in-game maps. A custom deque was created based on a vector, maintaining constant time operations by 
      automatically resizing upon element size changes. The algorithm calculates distances for every pixel along the map's paths, 
      determining the base's location as the longest 
      shortest path from a specified starting point. The resulting path is then visually rendered on the image, providing players 
      with a strategic perspective.
    `,
    technologies: ["C++"],
    images: [BaseMapGray, BaseMapRed, BaseMapPath],
  },
// ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "Automatic Human Feeder",
    link: "https://devpost.com/software/automatic-human-feeder",
    description: `
      Crafted a 3D printed robotic arm that utilizes face tracking to move a spoon toward your mouth, 
      streamlining the process of delivering food directly to the consumer. The design incorporates the ability to scoop 
      sustenance from a bowl, ascend towards the user with an open mouth, and retract upon closure, automating the 
      feeding process based on face tracking.

      This robot arm features custom-modded 3D printed components and is powered by an Arduino Uno, 
      equipped with 6 SG90s servos, and supported by 2 power supply modules. MediaPipe FaceMesh and OpenCV were used for facetracking, 
      capturing video from a USB external webcam mounted on the arm, drawing landmarks onto the video, 
      and extracting facial landmark positions for each frame. Leveraging the Jacobian pseudo-inverse method in NumPy, the face tracking 
      data is utilized to calculate reverse kinematics, determining the necessary angles for arm movement. The calculated data is then 
      transmitted to the Arduino board, loaded with the Firmata protocol, enabling real-time servo control using PyFirmata.
      `,
    technologies: [
      "Python",
      "Firmata",
      "Lilypad",
      "OpenCV",
      "PyFirmata",
      "Solidworks",
    ],
    images: [RobotTech, RobotCircuit, RobotArm, RobotDetection],
  },
// ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "Enspo",
    link: "https://devpost.com/software/enspo",
    description: `
      Developed a personalized shopping experience for Aritiza as a part of Girlcode 2023, allowing users to click on a preferred clothing item and 
      access an extension featuring a lookbook of stylistically similar pieces. Each lookbook is tailored to specific aesthetics, such as "cozy" or "athletic," generated 
      by backend that dynamically fetches AI-generated style recommendations based on keywords associated with the main clothing article. 
      The webpage, made in React, integrates with a dataset of 40 Aritzia clothing articles. Users can regenerate aesthetics multiple 
      times and save preferred looks to their personal lookbook, enhancing the overall customization and user-centric shopping journey.
      `,
    technologies: ["React", "ExpressJS", "HTML", "CSS", "GPT-3"],
    images: [Enspo],
  },
// ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "Craving Capybaras",
    link: "https://github.com/skyleapa/Craving-Capybaras",
    description: `
      A game centered around feeding a Capybara named Kiwi. Users can choose from a variety of food items, 
      including watermelon, library books, and fresh linen, to satisfy Kiwi's cravings. The game allows users to build a feeding history list, observe specific 
      reactions based on the food item chosen, and save or load their feeding history. Additionally, users can view the calorie count of the food items they've 
      fed Kiwi. The user interface, located at the top of the page, displays Kiwi's avatar and mood, while interactions are facilitated through buttons such as 
      'Feed Kiwi,' 'Save History,' and 'Load History.'
      `,
    technologies: ["Java", "JUnit"],
    images: [CapybaraMain, CapybaraFed, CapybaraHistory],
  },
];

// experiences array

const experiences = [
  {
    title: "Software Engineer Co-op @ Safe Software",
    technologies: "Python, C++, Jenkins/Cloudbees",
    duration: "May 2024 - August 2024",
    introduction:
      "During my co-op in the integrations team, I had the unique opportunity to contribute to critical aspects of FME’s third-party library management. This role blended technical challenges with a meaningful impact—ensuring compliance with security standards while enhancing developer efficiency. I honed my skills in Python, C++, and CI/CD tools, delivering solutions that streamlined workflows and safeguarded product integrity.",
    points: [
      {
        title: "Automated Third-Party Library Upgrades",
        description:
          `
          I built the foundation for upgrade pipelines in Python and Jenkins, 
          targeting libraries such as libcurl, libxml2, OpenSSL, and libssh2.
          This streamlined cross-platform builds for macOS (including ARM), 
          Linux, and Windows, cutting manual effort for developers in various teams.
          `,
      },
      {
        title: "CI/CD Integration and Efficiency Gains",
        description:
          `
          I designed pipelines to download source files, build binaries, 
          run automated tests, and generate pull requests. This reduced manual intervention,
          ensuring faster and more reliable updates for critical libraries.
          `,
      },
      {
        title: "Security and Compliance",
        description:
          `
          I created Python and C++ scripts to scan third-party components 
          for vulnerabilities, which ensured alignment with our security and compliance standards.
          I also implemented metadata tracking, providing transparency and auditability for all 
          integrated third-party libraries.
          `,
      },
    ],
  },
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "Software Engineer Co-op @ Electronic Arts",
    technologies: "C++, C#, Perforce",
    duration: "May 2023 - December 2023",
    introduction:
      "Engaged in the development of Frostbite Schematics, a visual scripting tool integral to EA's proprietary game engine.",
    points: [
      {
        title: "Contributed to enhancing workflow efficiency in FrostEd, the C# editor for the game engine",
        description:
          `
          Enhanced schematic groups by implementing header text wrapping,
          addressing group header truncation issues, and introducing
          contrasting colors based on background color for improved text
          visibility. Rectified a bug in the toolbox where the "Delete Subgraph" option
          was not displaying in subgraph context menus. Implemented annotations for graph connections, enabling text to
          appear on tooltips when hovering over connections, enhancing clarity
          and user understanding. Introduced auto connections for the addition of an input port,
          ensuring seamless automatic connections to entry points,
          streamlining the overall design process.
          `,
      },
      {
        title: "Enhanced expression debugging for 10+ game teams by developing instance selection feature",
        description:
          `
          Collaborated in a two-person team to address debug data display
          issues in multiple schematics instances within a graph. In the new
          release, introduced a feature allowing users to select and customize
          breakpoint and watch information for specific instances. My
          contributions included implementing a responsive UI dropdown tied to
          the active graph and session, registering instances through the C++
          backend, and introducing a new node for setting custom instance
          names displayed in FrostEd. Enhanced debugging by automatically
          displaying ECS entity names on the instance selector for
          EcsSchematicsInstances. Post-release, provided ongoing support
          through iterative development and bug fixing, addressing instance
          registration issues and ensuring proper association with the active
          session
          `,
      },
      {
        title: "Reduced Undefined Behaviour bugs for cross-platform PS5 builds by fixing member calls on unaligned addresses",
        description:
          `
          This includes work with conducting thorough autotests to identify
          errors within the codebase in C++. Implemented corrective measures
          through precise pointer arithmetic and utilized memcpys to ensure
          proper alignment, thereby enhancing the stability and reliability of
          the PS5 builds.
          `,
      },
    ],
  },
// ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "CS Undergraduate Teaching Assistant @ UBC",
    technologies: "C++, Java, Lisp",
    duration: "January 2023 - Present",
    introduction:
      "I've been a teaching assistant for various computer science courses throughout my time at UBC",
    points: [
      {
        title: "TA for CPSC 213 - Introduction to Computer Systems",
        description:
          `
          Leading weekly labs and developing exam questions and practice problems. 
          Question development is on prairielearn, with specific focus on dynamic control flow and
          reference counting questions.
          Course content includes control flow, reference counting and dynamic allocation, 
          procedures, the stack, virtual processing and synchronization.
          `,
      },
      {
        title: "TA for CPSC 110 - Computation, Programs, Programming",
        description:
          `
          Duties include guiding 700+ students by answering questions in
          office hours, assisting professor in lecture, leading weekly lab
          sessions, grading exams and problem sets. School content includes
          lessons on functional programming and key concepts such as
          encapsulation and abstraction. Focused on teaching students how to
          systematically solve hard design problems and provide guidance for
          future software engineering.
          `,
      },
    ],
  },
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  {
    title: "Game Developer @ UBC Game Dev Club",
    technologies: "C#, Unity, Github",
    duration: "September 2022 - March 2023",
    introduction:
      "Engaged in the development of Frostbite Schematics, a visual scripting tool integral to EA's proprietary game engine.",
    points: [
      {
        title: "Developer on game: Cybercision, a Cybernetic Surgery Simulator",
        description:
          `
          Contributed to the creative process by actively participating in
          brainstorming sessions for levels, gameplay mechanics, storyline
          elements, thematic elements, and worldbuilding. This involvement
          underscored a holistic approach to game development, ensuring a
          cohesive and immersive player experience within the Cybercision
          universe.
          `,
      },
      {
        title: "Designed and developed a shooter ”chip debugging” level",
        description:
          `
          Collaborated as a developer in a dynamic team of 10 on the game
          "Cybercision," a Cybernetic Surgery Simulator. Spearheaded the
          design and development of a captivating "chip debugging" level
          utilizing Unity and C#. The level featured recharging shots and a
          timed enemy respawn mechanism, enhancing player engagement and
          strategic gameplay.
          `,
      },
      {
        title: "Won ”Audience Choice” and ”Best Audio” at End of Year Showcase",
        description:
          `
          `,
      },
    ],
    link: "https://engineereng.itch.io/cybercision",
    image: Cybercision,
  },
];

function App() {
  return (
    <>
      <Constellation />
      <div className="App">
        <NavigationBar />
        <div style={styles.content}>
          <MainPage />
        </div>
        <div style={styles.content}>
          <About />
        </div>
        <div style={styles.content}>
          <Element name="experience">
          <div style={styles.experience}>
            ⋆｡ﾟ☁︎｡⋆｡ ﾟ☾ ﾟ｡⋆ Experience ⋆｡ﾟ☁︎｡⋆｡ ﾟ☾ ﾟ｡⋆
          </div>
          </Element>
            {experiences.map((experience, index) => (
              <Experience key={index} experience={experience} />
            ))}
        </div>
        <div style={styles.content}>
          <ProjectCarousel projects={projects} />
        </div>
        <div style={styles.content}>
          <Contact />
        </div>
        <div style={styles.content}>
          <footer style={styles.footer}>
            <p>&copy; 2024 Michelle X Wang. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </>
  );
}

const styles = {
  content: {
    position: "relative",
    margin: "5%",
    zIndex: 1, // Ensure this is higher than the z-index of FullPageFooter
    // Additional styling as needed
  },

  footer: {
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "absolute",
    bottom: "0",
    width: "100%",
  },

  experience: {
    fontSize: "1.8vw", // Dynamic font size based on screen width
    color: "#f7ea88",
    textAlign: "center",
    marginBottom: "4vw", // Adjust margin based on font size
    fontFamily: "RalewayBold",
  },

};

export default App;
