import React from "react";
import { Carousel } from "react-bootstrap";
import { Element } from "react-scroll";
import "./css/ProjectCarousel.css";
import LinkIcon from "../images/link-icon.png";

const ProjectCarousel = ({ projects }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh", // Ensure the container takes at least the full height of the viewport
  };

  const projectContainerStyle = {
    width: "60%",
    backgroundColor: "rgb(111, 48, 199)", // Deep purple background
    color: "white",
    borderRadius: "10px",
    overflow: "hidden",
    margin: "15px",
  };

  const header = {
    fontSize: "1.8vw",
    fontFamily: "RalewayBold",
    color: "#f7ea88",
    margin: "3vw",
    position: "relative",
    overflow: "hidden",
    textAlign: "center", // Align the text to the center
    width: "100%", // Full width of the container
  };

  const titleContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1vw",
  };

  const title = {
    fontFamily: "RalewayBlack",
    fontSize: "1.8vw",
  };

  const linkIcon = {
    width: "2vw", // Adjust the size of the link icon
    height: "2vw",
    cursor: 'url("../../images/cursor-hover.png"), auto !important',
  };

  const description = { fontSize: "1vw", marginTop: "1vw" };

  const technologies = {
    fontSize: "1.1vw",
    color: "#f7ea88",
  };

  return (
    <Element name="projects">
      <div style={containerStyle}>
        <div style={header}>🪐₊ ⊹⋆｡˚✧˖°. Projects ₊ ⊹⋆｡˚✧˖°.🪐</div>
        {projects.map((project, idx) => (
          <div key={idx} style={projectContainerStyle}>
            <Carousel className="carousel">
              {project.images.map((image, imageIdx) => (
                <Carousel.Item key={imageIdx}>
                  <img
                    className="d-block w-100"
                    src={image}
                    alt={`Project`}
                    style={{ maxHeight: "70vw", objectFit: "cover" }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <div style={{ padding: "2vw" }}>
              <div style={titleContainer}>
                <div style={title}>{project.title}</div>
                {project.link && (
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "1vw" }}
                  >
                    <img
                      src={LinkIcon}
                      alt="Project Link"
                      style={linkIcon}
                    />
                  </a>
                )}
              </div>
              <div style={technologies}>
                Technologies Used:{" "}
                <span>{project.technologies.join(", ")}</span>
              </div>
              <div style={description}>{project.description}</div>
            </div>
          </div>
        ))}
      </div>
    </Element>
  );
};

export default ProjectCarousel;
